import "flag-icons/sass/flag-icons.scss";
import "./styles/global.scss";
import "./styles/style.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";

import $ from "jquery";

global.$ = $;

$("#admin_box .icon").click(function () {
	$(this).parent().toggleClass("open");
});
$(document).ready(function () {
	$(".toggle-nav").click(function () {
		$("#menu nav").toggleClass("active");
		$("body").toggleClass("menu-open");
		$(this).toggleClass("is-active");
	});
	$(document).on("click", function (event) {
		if (event.target === $(".locale-switcher") || $(event.target).closest('.locale-switcher').length > 0) {
			$(".available-locales").addClass("active");
		} else {
			$(".available-locales").removeClass("active");
		}
	});
});

window.initCaptcha = function () {
	$("form[data-recaptcha-validation]").each(function () {

		$(this).find(".form-group:last-of-type").before(
			"<div class=\"form-group row pb-0\"> " +
			"<div class='col-12 d-flex flex-column align-items-center justify-content-center'>" +
			"<div class='g-recaptcha'></div>" +
			"<div class='form-error'>Merci de valider le captcha</div>" +
			"</div>" +
			"</div>",
		);

		const captcha_container = $(this).find(".g-recaptcha")[0];
		grecaptcha.render(captcha_container, {
			"sitekey": $(this).data("recaptcha-validation"),
		});
	});
};
